<template>
  <div fluid style="height: calc(100vh - 48px); position:relative;">
    <router-view />
  </div>
</template>
<script>

export default {
  name: "Documents",
}
</script>


